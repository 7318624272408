<template>
  <Html :lang="htmlAttrs.lang" :dir="htmlAttrs.dir">
    <div
      class="layout-wrapper flex min-h-screen flex-col"
      :class="`app theme-${$appConfig ? $theme : 1}`"
    >
      <Navbar />
      <main class="flex-grow pb-[3.75rem] md:px-[3.125rem] md:pb-20">
        <slot />
      </main>
      <Footer version="main" />
    </div>
  </Html>
</template>

<script lang="ts" setup>
import Navbar from "@/components/features/Header/Navbar.vue";
import Footer from "@/components/features/Footer/Footer.vue";

const head = useLocaleHead({
  addDirAttribute: true, // Adds dir
  addSeoAttributes: true, // Adds lang
});
const htmlAttrs = computed(() => head.value.htmlAttrs!);
</script>
